import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

import LoginView from '../views/LoginView.vue';

import RememberPasswordView from '../views/password/RememberPasswordView.vue';
import ReviewPasswordView from '../views/password/ReviewPasswordView.vue';
import NewPasswordView from '../views/password/NewPasswordView.vue';

/**
 * TODO: Implementar 404 https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
 *
 * TODO: Crear childrens: https://v3.router.vuejs.org/guide/essentials/nested-routes.html
 */

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
    meta: { guest: true },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { guest: true },
  },
{
    path: '/remember-password',
    name: 'remember-password',
    component: RememberPasswordView,
    meta: { guest: true },
  },

  {
    path: '/new-password',
    name: 'new-password',
    component: NewPasswordView,
    meta: { guest: true },
  },

  {
    path: '/review-password',
    name: 'review-password',
    component: ReviewPasswordView,
    meta: { guest: true },
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/lentes',
    name: 'lentes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lentes" */ '../views/LensesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/LogoutView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/notification',
    name: 'notification',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/NotificationView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/marketing/pedir-material',
    name: 'marketing/pedir-material',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/marketing/MarketingOrderView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/marketing/checkout',
    name: 'marketing/checkout',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/marketing/CheckoutView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/marketing',
    name: 'marketing',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/marketing/MarketingView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/reparacion',
    name: 'reparacion',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/RepareView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/consumibles',
    name: 'consumibles',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/ConsumablesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/bienes-de-equipo',
    name: 'bienes-de-equipo',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CapitalGoods.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/notification/detail/:noticia',
    name: 'notification-detail',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/notification/NotificationDetailView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/indomanager',
    name: 'facturacion',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/FacturationView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/FaqsView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/pedir-lentes/datos-basicos',
    name: 'pedir-lentes',
    component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/OrderLensesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/pedir-lentes/formas-espesores',
    name: 'formas-espesores',
    component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/FormsThicknessesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/pedir-lentes/indocenter',
    name: 'indocenter',
    component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/IndocenterView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/pedir-lentes/enviar-pedido',
    name: 'enviar-pedido',
    component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/SendOrderView.vue'),
    meta: { requiresAuth: true },
  },

  // {
  //   path: '/pedir-lentes/trazador',
  //   name: 'trazador',
  //   component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/TrazadorView.vue'),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path: '/pedir-lentes/indoscan',
  //   name: 'indoscan',
  //   component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/IndoscanView.vue'),
  //   meta: { requiresAuth: true },
  // },

  {
    path: '/pedir-lentes/pedido-enviado',
    name: 'pedido-enviado',
    component: () => import(/* webpackChunkName: "pedirLentes" */ '../views/orderLenses/OrderSendedView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/formacion',
    name: 'formacion',
    component: () => import(/* webpackChunkName: "formacion" */ '../views/FormationView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/lentes/pedidos',
    name: 'pedidos',
    component: () => import(/* webpackChunkName: "orders" */ '../views/lenses/OrdersView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/lentes/devoluciones',
    name: 'devoluciones',
    component: () => import(/* webpackChunkName: "orders" */ '../views/lenses/ReturnsView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/lentes/pedidos/analizar',
    name: 'analizar',
    component: () => import(/* webpackChunkName: "orders" */ '../views/lenses/LensesAnalize.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/lentes/recetas-guardadas',
    name: 'recetas-guardadas',
    component: () => import(/* webpackChunkName: "orders" */ '../views/lenses/RecipesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/indomanager/informacion-fiscal',
    name: 'informacion-fiscal',
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/FiscalInformationView.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: '/indomanager/stats',
    name: 'informacion-servicio',
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/ServiceInformationView.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: '/indomanager/bonos',
    name: 'bonos',
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/BonosView.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: '/indomanager/acuerdos-comerciales',
    name: 'acuerdos-comerciales',
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/CommercialsAgreements.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: '/indomanager/facturas-y-albaranes',
    name: 'facturas-y-albaranes',
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/InvoicesDeliveryNotesView.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },

  {
    path: '/indomanager/facturas-y-albaranes/abrir-consulta/:item',
    name: 'abrir-consulta',
    props: { item: true },
    component: () => import(/* webpackChunkName: "facturacion" */ '../views/facturation/ConsultingFormView.vue'),
    meta: { requiresAuth: true, requiresManager: true },
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/MyProfileView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/optic-data',
    name: 'optic-data',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/OpticDataView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/websearch',
    name: 'websearch',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/WebsearchView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/mailbox',
    name: 'mailbox',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/MailboxView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/calibration-monitor',
    name: 'calibration-monitor',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/CalibrationMonitorView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/plotters',
    name: 'plotters',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/PlottersView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/favorites',
    name: 'favorites',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/FavoritesView.vue'),
    meta: { requiresAuth: true },
  },

  {
    path: '/settings/configuration-indonet',
    name: 'configuration-indonet',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/ConfigurationIndonetView.vue'),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth && record.meta.requiresManager)) {
    if (store.getters.isAuthenticated && store.getters.isAuthManager) {
      next();
      return;
    }
    next('/indomanager');
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else if (to.matched.some((record) => !record.meta.guest) && store.getters.isAuthenticated) {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;
