import indo from '@/config/indo';

const transformArray = (arrData, newParent) => {
  let newData = [...arrData];
  const temp = [...newData];
  newData = {};
  newData[newParent] = temp;

  return newData;
};

const parseDom = (data, domRoot) => {
  const xml = new DOMParser();
  const doc = xml.parseFromString(data, 'text/xml');
  return doc.getElementsByTagName(domRoot)[0].innerHTML;
};

const decodeHtmlEntities = (str) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
}

export default {
  login(response) {
    return JSON.parse(parseDom(response.data, indo.login.methods.response));
  },

  loginManager(response) {
    return JSON.parse(parseDom(response.data, indo.loginManager.methods.response));
  },

  postLogin(response) {
    const xml = new DOMParser();
    const doc = xml.parseFromString(response.data, 'text/xml');
    const data = doc.getElementsByTagName(indo.postLogin.methods.response)[0].innerHTML;
    const jsonData = JSON.parse(data);
    /* const fav = [...postData.DatosConfig.listaFavoritos];
    postData.DatosConfig.listaFavoritos = [];
    postData.DatosConfig.listaFavoritos.clsFavoritos = fav;
    const espesores = [...jsonData.DatosConfig.ESPESORES];
    jsonData.DatosConfig.ESPESORES = {
      clsZES_INET_ESPCLI: espesores,
    }; */

    jsonData.DatosConfig.ESPESORES = transformArray(jsonData.DatosConfig.ESPESORES, 'clsZES_INET_ESPCLI');
    jsonData.DatosConfig.listaFavoritos = transformArray(jsonData.DatosConfig.listaFavoritos, 'clsFavoritos');
    if (import.meta.env.NODE_ENV === 'development') console.log(jsonData);
    return jsonData;
  },

  pedidos(response) {
    return JSON.parse(parseDom(response.data, indo.pedidos.methods.response));
  },

  nuevoPedido(response) {
    return parseDom(response.data, indo.nuevoPedido.methods.response);
  },

  ajustes(response) {
    return JSON.parse(parseDom(response.data, indo.ajustes.methods.response));
  },

  buzon(response) {
    return JSON.parse(parseDom(response.data, indo.miBuzon.methods.response));
  },

  cambioPassword(response) {
    return JSON.parse(parseDom(response.data, indo.cambioPassword.methods.response));
  },

  searchManagerFacturas(response) {
    return JSON.parse(parseDom(response.data, indo.buscarListaFacturas.methods.response));
  },

  bonos(response) {
    return JSON.parse(parseDom(response.data, indo.bonos.methods.response));
  },

  facturaPdf(response) {
    return JSON.parse(parseDom(response.data, indo.facturaSap.methods.response));
  },

  bonoDetalle(response) {
    return JSON.parse(parseDom(response.data, indo.bonoDetalle.methods.response));
  },

  estadisticaServicio(response) {
    return JSON.parse(parseDom(response.data, indo.estadisticaServicio.methods.response));
  },

  estadisticaConsumo(response) {
    return JSON.parse(parseDom(response.data, indo.estadisticaConsumo.methods.response));
  },

  getBases(response) {
    return JSON.parse(parseDom(response.data, indo.basesEspeciales.methods.response));
  },

  enviarEmail(response) {
    return JSON.parse(parseDom(response.data, indo.enviarEmail.methods.response));
  },

  prepararPedido(response) { // calcular
    return JSON.parse(parseDom(response.data, indo.prepararPedido.methods.response));
  },

  realizarPedido(response) {
    return JSON.parse(parseDom(response.data, indo.realizarPedido.methods.response));
  },

  pedirContrasena(response) {
    return JSON.parse(parseDom(response.data, indo.pedirContrasena.methods.response));
  },

  cambiarContrasena(response) {
    return JSON.parse(parseDom(response.data, indo.cambiarContrasena.methods.response));
  },

  monturas(response) {
    return JSON.parse(parseDom(response.data, indo.monturas.methods.response));
  },

  formas(response) {
    return JSON.parse(parseDom(response.data, indo.guardarForma.methods.response));
  },

  buscarFormas(response) {
    return JSON.parse(parseDom(response.data, indo.buscarForma.methods.response));
  },

  formaFromImagenPicture(response) {
    return parseDom(response.data, indo.getFormaFromImagen.methods.result);
  },

  formaFromImagenOtrosDatos(response) {
    const xml = parseDom(response.data, indo.getFormaFromImagen.methods.otrosDatos);
    return decodeHtmlEntities(xml);
  },
};
