import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import requests from '@/services/requests';
import response from '@/services/response';

const estado = {

};

const getters = {

};

const actions = {
  async SendImagen({ commit }, { imagen, datos }) {
    const data = await requests.indoscan.formaFromImagen(imagen, datos);

    if (data.status === 200) {
      // inicializamos el parser XML para interpretar los datos
      const p = new XMLParser({
        numberParseOptions: {
        leadingZeros: false,
        }
      });
      const picture     = response.formaFromImagenPicture(data);
      if (!picture.startsWith('H4sI')) {
        return { error: 'No se ha podido procesar el fichero.' };
      }
      // enviamos al servicio response para extraer los datos y los pasamos al parser
      // para generar un objeto JSON
      const otrosDatos  = p.parse(response.formaFromImagenOtrosDatos(data));

      return { picture, otrosDatos };
    }
  },
};

const mutations = {

};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
