import { createApp } from 'vue';
import { clear } from 'idb-keyval';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import './index.scss';

// console.log(import.meta.env);

window.addEventListener('beforeunload', async (event) => {
  try {
    // await clear();
  } catch (error) {
    console.error('Error al limpiar IndexedDB:', error);
  }
});

if (import.meta.env.MODE !== 'development') {
  window.consoleHolder = window.console;
  window.console = {};
  Object.keys(window.consoleHolder).forEach((key) => {
    console[key] = () => {};
  });
}

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');

// https://medium.com/simform-engineering/globalize-your-vue-app-a-practical-guide-to-vue-3-internationalization-93f5c7da4494
// https://lokalise.com/blog/vue-i18n/
// https://stackoverflow.com/questions/68802337/vue-js-i18n-load-external-jsons-from-server-and-make-it-globally-accessible-i
