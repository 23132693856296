import requests from '@/services/requests';

const estado = {

};

const getters = {
  cursos: (state) => state.cursos,
};

const actions = {
  async GetCursos({ commit, dispatch }) {
    const me = this;
    const data = await requests.academy.cursos();

    // filter by visible and start_date after today
    const cursosPromises = data.data
                      .filter((curso) => curso.visible !== 0)
                      .sort((a, b) => b.startdate - a.startdate)
                      .map(async (c) => {
                        const curso = await dispatch('GetCurso', { curso_id: c.id });
                        return { ...c, ...curso };
                      });

    const cursos = await Promise.all(cursosPromises);

    await commit('setCursos', cursos);
    console.log(cursos);
    return cursos;
  },
  async GetCurso({ }, { curso_id }) {
    const { data, status } = await requests.academy.curso(curso_id);

    if (status !== 200) {
      return null;
    }

    return data.courses[0];
  },
};

const mutations = {
  setCursos(state, data) {
    state.cursos = data;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
