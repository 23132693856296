/* eslint-disable no-return-await */
import { XMLParser, XMLBuilder } from 'fast-xml-parser';

import emails from '@/config/emails';
import { json2xml } from '@/helpers/xml';
import requests from '@/services/requests';
import response from '@/services/response';
import moment from 'moment';

const estado = {
  profile: null,
  erroresPedido: [],
};

const getters = {
  profile: (state) => {
    console.log(state);
    return state.profile;
  },
  pedidos: (state) => state.pedidos
                          && state.pedidos.oClaseBAPI
                          && state.pedidos.oClaseBAPI,
  homePedidos: (state) => state.pedidos
                          && state.pedidos.oClaseBAPI
                          && state.pedidos.oClaseBAPI.listLISTAPEDIDOS,
  nuevoPedido: (state) => state.nuevoPedido,
  erroresPedido: (state) => state.erroresPedido,
};

const actions = {
  async PostLogin({ commit, rootState }) {
    const data = await requests.postLogin(rootState.auth.username, rootState.auth.token);
    const postData = response.postLogin(data);
    console.log(postData);
    if (postData.returnValue === 0) {
      await commit('setProfile', postData);
    } else {
      throw new Error(postData.MensajeErrorSAP);
    }
  },

  async GetPedidos(
    { commit, dispatch, rootState, state },
    fechas,
  ) {
    let { fechaIni = null, fechaFin = null } = fechas;
    // this.profile.DatosConfig.DETALLES.DIAS_PTE_VL
    if (fechaIni === null || fechaIni === undefined) {
      fechaIni = moment().subtract(state.profile.DatosConfig.DETALLES.DIAS_PTE_VL, 'days').toDate();
    }
    if (fechaFin === null || fechaFin === undefined) {
      fechaFin = moment().add(state.profile.DatosConfig.DETALLES.DIAS_PTE_VL, 'days').toDate();
    }

    const data = await requests.getPedidos(rootState.auth.username, rootState.auth.token, state.profile, fechaIni, fechaFin);
    const postData = response.pedidos(data);

    if (postData.returnValue === 0) {
      await commit('setPedidos', postData);
    } else {
      if (postData.returnValue === 402) {
        dispatch('Logout', { root: true });
        return 402;
      }
      throw new Error(postData.MensajeErrorSAP);
    }
    return true;
  },

  async GetNuevoPedido({ commit, rootState, state }) {
    const data = await requests.getNuevoPedido(rootState.auth.token);
    const postData = response.nuevoPedido(data);
    const p = new XMLParser({
          numberParseOptions: {
          leadingZeros: false,
      }
    });

    const newData = p.parse('<oClaseBAPI>' + postData + '</oClaseBAPI>');

    const areaCliente = rootState.areaCliente || state.profile.AreaVenta.listaAreasCliente[0];

    newData.oClaseBAPI.EDITABLE                                 = state.profile.AreaVenta.TIPO_CLIENTE !== 'J';
    newData.oClaseBAPI.CLIENTE                                  = state.profile.DatosConfig.CUSTOMERNO;
    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.KUNNR         = state.profile.DatosConfig.CLIENTESAP;
    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.TIPO_POS_USO  = state.profile.DatosConfig.CONFIGURACION.TIPO_POS_USO;

    newData.oClaseBAPI.listCABFRM.clsZES_CAB_FORMA.TIPO_ALTURA  = state.profile.DatosConfig.CONFIGURACION.TIPO_ALT;

    newData.oClaseBAPI.VKORG                                    = areaCliente.ORG_VENTA;
    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.VKORG         = areaCliente.ORG_VENTA;

    newData.oClaseBAPI.VTWEG                                    = areaCliente.CANAL_DIST;
    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.VTWEG         = areaCliente.CANAL_DIST;

    newData.oClaseBAPI.SPART                                    = areaCliente.SECTOR;
    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.SPART         = areaCliente.SECTOR;

    newData.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.VKORG_DESCR   = areaCliente.texto_organizacion.toUpperCase();

    const processedData = helpers.processAfterReceivePedido(newData);

    commit('setNuevoPedido', processedData);

    return processedData;
  },

  async SaveAjustes({ commit, rootState }, { datosConfig }) {
    await commit('setDatosConfig', datosConfig);

    const data = await requests.saveAjustes(json2xml({ oClaseBAPI: datosConfig }), rootState.auth.token);
    const postData = response.ajustes(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }
  },

  async SaveMailbox({ rootState }, { datosConfig }) {
    const data = await requests.saveMailbox(json2xml({ oClaseBAPI: datosConfig }), rootState.auth.token);
    const postData = response.buzon(data);
    console.log(postData);
    if (postData.returnValue !== 0) {
      return postData.MensajeErrorSAP;
    }

    return '';
  },

  async ManagerBuscarFacturas({ rootState }, fechas) {
    let dates = fechas;

    if (fechas === undefined) {
      dates = {
        from: moment().subtract(24, 'months').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        noclient: '',
        noalb: '',
        nofact: '',
      };
    }

    if (fechas !== undefined && fechas.from === null) {
      dates.from = moment().subtract(4, 'years').format('YYYY-MM-DD');
    }

    if (fechas !== undefined && fechas.to === null) {
      dates.to = moment().format('YYYY-MM-DD');
    }

    const {
      from, to, noclient, nofact, noalb,
    } = dates;

    const data = await requests.searchManagerFacturas(rootState.auth.username, rootState.auth.tokenManager, rootState.auth.indoManager, from, to, noclient, nofact, noalb);
    const postData = response.searchManagerFacturas(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.ExportReturnMessage);
    }
    return postData.oClaseBAPI.reverse();
  },

  async ManagerGetBonos({ rootState }) {
    const data = await requests.getBonos(rootState.auth.username, rootState.auth.tokenManager, rootState.auth.indoManager);
    const postData = response.bonos(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.ExportReturnMessage);
    }
    return postData.oClaseBAPI;
  },

  async ManagerGetFactura({ rootState }, { factura, fechaFactura }) {
    const data = await requests.getFacturaPdf(rootState.auth.username, rootState.auth.tokenManager, rootState.auth.indoManager, factura, fechaFactura);
    const postData = response.facturaPdf(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.ExportReturnMessage);
    }
    return postData.oRespuesta;
  },

  async ManagerGetBonoDetalle({ rootState }, { bono }) {
    const data = await requests.getBonoDetalle(rootState.auth.tokenManager, rootState.auth.indoManager, bono);
    const postData = response.bonoDetalle(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.ExportReturnMessage);
    }
    return postData.oClaseBAPI;
  },

  async ManagerGetAllBonoDetalle({ dispatch }, { bonos }) {
    const data = await Promise.all(bonos.map(async (bono) => (await dispatch('ManagerGetBonoDetalle', { bono }))));

    let ret = [];
    data.forEach((d) => {
      ret = [...d.listaRespuestaDetallePuntosBonos, ...ret];
    });

    return ret;
  },

  async ManagerGetServicioEstadisticas({ rootState }) {
    const dataS = await requests.getEstadisticaServicio(rootState.auth.tokenManager, rootState.auth.indoManager);
    const postDataS = response.estadisticaServicio(dataS);

    const dataC = await requests.getEstadisticaConsumo(rootState.auth.tokenManager, rootState.auth.indoManager);
    const postDataC = response.estadisticaConsumo(dataC);

    if (postDataS.returnValue !== 0) {
      throw new Error(postDataS.ExportReturnMessage);
    }

    if (postDataC.returnValue !== 0) {
      throw new Error(postDataS.ExportReturnMessage);
    }
    return { ...postDataC.oClaseBAPI, ...postDataS.oClaseBAPI };
  },

  async ManagerSendEmail347({ rootState, state }) {
    let sendTo = state.profile.AreaVenta.datosCliente.SU_GESTOR.CUENTA_CORREO;
    if (import.meta.env.MODE === 'development' || sendTo === '') {
      sendTo = 'victor.santacreu@jelliby.com';
    }
    const body = `CLIENTE: ${state.profile.AreaVenta.datosCliente.CLIENTE}
    NOMBRE: ${state.profile.AreaVenta.datosCliente.NOMBRE}

    `;
    const req = await requests.enviarEmail(rootState.auth.token, sendTo, emails.fiscal.subject, body + emails.fiscal.body);
    const res = response.enviarEmail(req);

    if (res.returnValue != 0) {
      throw new Error(res.mensajeError);
    }

    return null;
  },

  async ManagerSendEmailFacturas({ rootState }) {
    console.log(rootState.auth.indoManager);
    return null;
  },

  async GetBases({ rootState, state }, { pedido }) {
    const data = await requests.getBases(
      rootState.auth.token,
      json2xml(pedido),
    );
    const postData = response.getBases(data);

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }
    return postData.oClaseBAPI;
  },

  async PrepararPedido({ commit, rootState, state }, { pedido }) { // Calcular

    const processedOrder = {
      oClaseBAPI: { ...pedido }
    }

    const xml = json2xml(helpers.processBeforeSendPedido(processedOrder).oClaseBAPI);
    const data = await requests.prepararPedido(rootState.auth.token, xml);
    let postData = response.prepararPedido(data);

    await commit('emptyErroresPedido');

    // procesamos primero ya que tenemos que coger los errores
    const processedData = helpers.processAfterReceivePedido(postData);
    if (processedData.oClaseBAPI.listTRETURN.clsBAPIRET1.length > 0) {
      await commit('setErroresPedido', processedData.oClaseBAPI.listTRETURN.clsBAPIRET1);
    }

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }

    await commit('setNuevoPedido', processedData);

    return state.nuevoPedido;
  },

  async NormalizarPedido({}, { pedido }) {
    const processedData = helpers.processAfterReceivePedido(pedido);

    return processedData;
  },

  async RealizarPedido({ commit, rootState, state }) {
    const xml = json2xml(helpers.processBeforeSendPedido(state.nuevoPedido).oClaseBAPI);
    const data = await requests.realizarPedido(rootState.auth.token, xml);
    let postData = response.realizarPedido(data);

    await commit('emptyErroresPedido');

    const processedData = helpers.processAfterReceivePedido(postData);
    if (processedData.oClaseBAPI.listTRETURN.clsBAPIRET1.length > 0) {
      await commit('setErroresPedido', processedData.oClaseBAPI.listTRETURN.clsBAPIRET1);
    }

    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }

    await commit('setNuevoPedido', processedData);

    return state.nuevoPedido;
  },
  GetErroresPedido({ state }) {
    return state.erroresPedido;
  },
  GuardarPedido({ commit }, { pedido }) {
    commit('setNuevoPedido', {
      oClaseBAPI: { ...pedido },
    });
  },

};


const mutations = {
  setProfile(state, data) {
    state.profile = data;
  },
  setPedidos(state, data) {
    state.pedidos = data;
  },
  setNuevoPedido(state, data) {
    state.nuevoPedido = helpers.processAfterReceivePedido(data);
  },
  setDatosConfig(state, data) {
    if (state.profile.DatosConfig) {
      state.profile.DatosConfig = data;
    }
  },
  setErroresPedido(state, data) {
    if (data.length > 0) {
      const errores = data.map(t => {
          let msg = '';
          switch (t.TYPE) {
            case 'E':
              msg = 'Error: ';
              break;
            case 'W':
              msg: 'Atención: ';
              break;
            case 'I':
              msg = 'Información: ';
              break;
          }
          return `${msg}${t.MESSAGE} ${t.MESSAGE_V1 ?? ''} ${t.MESSAGE_V2 ?? ''} ${t.MESSAGE_V3 ?? ''} ${t.MESSAGE_V4 ?? ''}`;
        });
      state.erroresPedido = errores;
    }
  },
  emptyErroresPedido(state) {
    state.erroresPedido = [];
  },
  emptyData(state) {
    state.profile = null;
    state.pedidos = null;
    state.homePedidos = null;
    state.nuevoPedido = null;
    state.erroresPedido = [];
  },
};

const helpers = {
  /**
   * No usar, elimina partes del JSOn como lo cls_
   * @param {*} postData
   * @returns
   * @deprecated
   */
  processPostdata(postData) {
    // We need to parse because .net not serialize XML2Json in a good way
    if (postData.ArrayDataTypes) {
      for (const [key, value] of Object.entries(postData.ArrayDataTypes)) {
          if (postData.oClaseBAPI[key]) {
            let tempData = {};
            tempData[key] = [];
            tempData = [...postData.oClaseBAPI[key]];
            postData.oClaseBAPI[key] = {};
            postData.oClaseBAPI[key][value] = [...tempData];
          }
      }
    }

    return postData;
  },
  /**
   * Nos aseguramos que el objeto pedido cumpla con la estructura del XML
   * @param {object} pedido
   * @returns {object} pedido
   */
  processAfterReceivePedido(pedido) {

    // listCABIN
    if (!pedido.oClaseBAPI.listCABIN || pedido.oClaseBAPI.listCABIN === '' ||
      (Array.isArray(pedido.oClaseBAPI.listCABIN) && pedido.oClaseBAPI.listCABIN.length === 0)) {
      pedido.oClaseBAPI.listCABIN = {
        clsZES_CAB_INPUT: []
      };
    }

    if (Array.isArray(pedido.oClaseBAPI.listCABIN) && pedido.oClaseBAPI.listCABIN.length > 0 && !pedido.oClaseBAPI.listCABIN.clsZES_CAB_INPUT) {
      const inTemp = [ ...pedido.oClaseBAPI.listCABIN ];
      pedido.oClaseBAPI.listCABIN = {
        clsZES_CAB_INPUT: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listCABIN.clsZES_CAB_INPUT)) {
      const inTemp = { ...pedido.oClaseBAPI.listCABIN.clsZES_CAB_INPUT };
      pedido.oClaseBAPI.listCABIN = {
        clsZES_CAB_INPUT: [inTemp],
      }
    }

    // listCABOUT
    if (!pedido.oClaseBAPI.listCABOUT || pedido.oClaseBAPI.listCABOUT === '' ||
      (Array.isArray(pedido.oClaseBAPI.listCABOUT) && pedido.oClaseBAPI.listCABOUT.length === 0)) {
      pedido.oClaseBAPI.listCABOUT = {
        clsZES_CAB_OUTPUT: []
      };
    }

    if (Array.isArray(pedido.oClaseBAPI.listCABOUT) && pedido.oClaseBAPI.listCABOUT.length > 0 && !pedido.oClaseBAPI.listCABOUT.clsZES_CAB_OUTPUT) {
      const inTemp = [ ...pedido.oClaseBAPI.listCABOUT ];
      pedido.oClaseBAPI.listCABOUT = {
        clsZES_CAB_OUTPUT: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listCABOUT.clsZES_CAB_OUTPUT)) {
      const inTemp = { ...pedido.oClaseBAPI.listCABOUT.clsZES_CAB_OUTPUT };
      pedido.oClaseBAPI.listCABOUT = {
        clsZES_CAB_OUTPUT: [inTemp],
      }
    }

    // listCABFRM
    if (!pedido.oClaseBAPI.listCABFRM || pedido.oClaseBAPI.listCABFRM === '' ||
      (Array.isArray(pedido.oClaseBAPI.listCABFRM) && pedido.oClaseBAPI.listCABFRM.length === 0)) {
      pedido.oClaseBAPI.listCABFRM = {
        clsZES_CAB_FORMA: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listCABFRM) && pedido.oClaseBAPI.listCABFRM.length > 0 && !pedido.oClaseBAPI.listCABFRM.clsZES_CAB_FORMA) {
      const inTemp = [ ...pedido.oClaseBAPI.listCABFRM ];
      pedido.oClaseBAPI.listCABFRM = {
        clsZES_CAB_FORMA: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listCABFRM.clsZES_CAB_FORMA)) {
      const inTemp = { ...pedido.oClaseBAPI.listCABFRM.clsZES_CAB_FORMA };
      pedido.oClaseBAPI.listCABFRM = {
        clsZES_CAB_FORMA: [inTemp],
      }
    } else if (pedido.oClaseBAPI.CABFRM) {
      const inTemp = { ...pedido.oClaseBAPI.CABFRM };
      pedido.oClaseBAPI.listCABFRM = {
        clsZES_CAB_FORMA: [ inTemp ],
      }
    }

    // listOJOIN
    if (!pedido.oClaseBAPI.listOJOIN || pedido.oClaseBAPI.listOJOIN === '' ||
      (Array.isArray(pedido.oClaseBAPI.listOJOIN) && pedido.oClaseBAPI.listOJOIN.length === 0)) {
      pedido.oClaseBAPI.listOJOIN = {
        clsZES_OJO_INPUT: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listOJOIN) && pedido.oClaseBAPI.listOJOIN.length > 0 && !pedido.oClaseBAPI.listOJOIN.clsZES_OJO_INPUT) {
      const inTemp = [ ...pedido.oClaseBAPI.listOJOIN ];
      pedido.oClaseBAPI.listOJOIN = {
        clsZES_OJO_INPUT: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listOJOIN.clsZES_OJO_INPUT)) {
      const inTemp = { ...pedido.oClaseBAPI.listOJOIN.clsZES_OJO_INPUT };
      pedido.oClaseBAPI.listOJOIN = {
        clsZES_OJO_INPUT: [inTemp],
      }
    }

    // listOJOOUT
    if (!pedido.oClaseBAPI.listOJOOUT || pedido.oClaseBAPI.listOJOOUT === '' ||
      (Array.isArray(pedido.oClaseBAPI.listOJOOUT) && pedido.oClaseBAPI.listOJOOUT.length === 0)) {
      pedido.oClaseBAPI.listOJOOUT = {
        clsZES_OJO_OUTPUT: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listOJOOUT) && pedido.oClaseBAPI.listOJOOUT.length > 0 && !pedido.oClaseBAPI.listOJOOUT.clsZES_OJO_OUTPUT) {
      const oOutTemp = [ ...pedido.oClaseBAPI.listOJOOUT ];
      pedido.oClaseBAPI.listOJOOUT = {
        clsZES_OJO_OUTPUT: [...oOutTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listOJOOUT.clsZES_OJO_OUTPUT)) {
      const oOutTemp = { ...pedido.oClaseBAPI.listOJOOUT.clsZES_OJO_OUTPUT };
      pedido.oClaseBAPI.listOJOOUT = {
        clsZES_OJO_OUTPUT: [oOutTemp],
      }
    }

    // listFORMAS
    if (!pedido.oClaseBAPI.listFORMAS || pedido.oClaseBAPI.listFORMAS === '' ||
      (Array.isArray(pedido.oClaseBAPI.listFORMAS) && pedido.oClaseBAPI.listFORMAS.length === 0)) {
      pedido.oClaseBAPI.listFORMAS = {
        clsZFORMAS: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listFORMAS) && pedido.oClaseBAPI.listFORMAS.length > 0 && !pedido.oClaseBAPI.listFORMAS.clsZFORMAS) {
      const oOutTemp = [ ...pedido.oClaseBAPI.listFORMAS ];
      pedido.oClaseBAPI.listFORMAS = {
        clsZFORMAS: [...oOutTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listFORMAS.clsZFORMAS)) {
      const oOutTemp = { ...pedido.oClaseBAPI.listFORMAS.clsZFORMAS };
      pedido.oClaseBAPI.listFORMAS = {
        clsZFORMAS: [oOutTemp],
      }
    }

    // listTALADROS
    if (!pedido.oClaseBAPI.listTALADROS || pedido.oClaseBAPI.listTALADROS === '' ||
      (Array.isArray(pedido.oClaseBAPI.listTALADROS) && pedido.oClaseBAPI.listTALADROS.length === 0)) {
      pedido.oClaseBAPI.listTALADROS = {
        clsZES_APLT_TLDROS: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listTALADROS) && pedido.oClaseBAPI.listTALADROS.length > 0 && !pedido.oClaseBAPI.listTALADROS.clsZES_APLT_TLDROS) {
      const inTemp = [ ...pedido.oClaseBAPI.listTALADROS ];
      pedido.oClaseBAPI.listTALADROS = {
        clsZES_APLT_TLDROS: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listTALADROS.clsZES_APLT_TLDROS)) {
      const inTemp = { ...pedido.oClaseBAPI.listTALADROS.clsZES_APLT_TLDROS };
      pedido.oClaseBAPI.listTALADROS = {
        clsZES_APLT_TLDROS: [inTemp],
      }
    }

    // listBASES
    if (!pedido.oClaseBAPI.listBASES || pedido.oClaseBAPI.listBASES === '' ||
      (Array.isArray(pedido.oClaseBAPI.listBASES) && pedido.oClaseBAPI.listBASES.length === 0)) {
      pedido.oClaseBAPI.listBASES = {
        clsZES_BASENOM: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listBASES) && pedido.oClaseBAPI.listBASES.length > 0 && !pedido.oClaseBAPI.listBASES.clsZES_BASENOM) {
      const inTemp = [ ...pedido.oClaseBAPI.listBASES ];
      pedido.oClaseBAPI.listBASES = {
        clsZES_BASENOM: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listBASES.clsZES_BASENOM)) {
      const inTemp = { ...pedido.oClaseBAPI.listBASES.clsZES_BASENOM };
      pedido.oClaseBAPI.listBASES = {
        clsZES_BASENOM: [inTemp],
      }
    }

    // listTRETURN
    if (!pedido.oClaseBAPI.listTRETURN || pedido.oClaseBAPI.listTRETURN === '' ||
      (Array.isArray(pedido.oClaseBAPI.listTRETURN) && pedido.oClaseBAPI.listTRETURN.length === 0)) {
      pedido.oClaseBAPI.listTRETURN = {
        clsBAPIRET1: []
      };
    }
    if (Array.isArray(pedido.oClaseBAPI.listTRETURN) && pedido.oClaseBAPI.listTRETURN.length > 0 && !pedido.oClaseBAPI.listTRETURN.clsBAPIRET1) {
      const inTemp = [ ...pedido.oClaseBAPI.listTRETURN ];
      pedido.oClaseBAPI.listTRETURN = {
        clsBAPIRET1: [...inTemp],
      }
    } else if (!Array.isArray(pedido.oClaseBAPI.listTRETURN.clsBAPIRET1)) {
      const inTemp = { ...pedido.oClaseBAPI.listTRETURN.clsBAPIRET1 };
      pedido.oClaseBAPI.listTRETURN = {
        clsBAPIRET1: [inTemp],
      }
    }

    return pedido;
  },
  /**
   * Preparamos el objeto para ser transformado al XML. Elementos que no enviamos los vaciamos
   * @param {object} pedido
   * @returns {object} pedido
   */
  processBeforeSendPedido(pedido) {

    // listCABIN
    if (pedido.oClaseBAPI.listCABIN.clsZES_CAB_INPUT.length === 0) {
      pedido.oClaseBAPI.listCABIN = '';
    }

    // listCABOUT
    if (pedido.oClaseBAPI.listCABOUT.clsZES_CAB_OUTPUT.length === 0) {
      pedido.oClaseBAPI.listCABOUT = '';
    }

    // listCABFRM
    if (pedido.oClaseBAPI.listCABFRM.clsZES_CAB_FORMA.length === 0) {
      pedido.oClaseBAPI.listCABFRM = '';
    }

    // listOJOIN
    if (pedido.oClaseBAPI.listOJOIN.clsZES_OJO_INPUT.length === 0) {
      pedido.oClaseBAPI.listOJOIN = '';
    }

    // listOJOOUT
    if (pedido.oClaseBAPI.listOJOOUT.clsZES_OJO_OUTPUT.length === 0) {
      pedido.oClaseBAPI.listOJOOUT = '';
    }

    // listFORMAS
    if (pedido.oClaseBAPI.listFORMAS.clsZFORMAS.length === 0) {
      pedido.oClaseBAPI.listFORMAS = '';
    }

    // listTALADROS
    if (pedido.oClaseBAPI.listTALADROS.clsZES_APLT_TLDROS.length === 0) {
      pedido.oClaseBAPI.listTALADROS = '';
    }

    // listBASES
    if (pedido.oClaseBAPI.listBASES.clsZES_BASENOM.length === 0) {
      pedido.oClaseBAPI.listBASES = '';
    }

    // listTRETURN
    if (pedido.oClaseBAPI.listTRETURN.clsBAPIRET1.length === 0) {
      pedido.oClaseBAPI.listTRETURN = '';
    }

    return pedido;
  },
};

const plugin = store => {
  // Suscribirse a cambios en el state
  store.subscribe((mutation, state) => {
    if (mutation.type === 'RESTORE_MUTATION') {
      // Si tenemos datos de indo en el state restaurado, actualizamos
      if (state.indo && state.indo.profile) {
        store.commit('setProfile', state.indo.profile);
      }
    }
  });
};


export default {
  state: estado,
  getters,
  actions,
  mutations,
  plugin
};
