import { createI18n } from "vue-i18n";
import es from "./locales/es.json";
import en from "./locales/en.json";

const loadLocaleMessages = () => {
  const locales = [{ en: en }, { es: es }];
  const messages = {};
  locales.forEach((lang) => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
};

export default createI18n({
  locale: "es",
  fallbackLocale: "es",
  legacy: false,
  messages: loadLocaleMessages(),
});
