import axios from 'axios';
import axiosConfig from '@/config/axios';
import config from '@/config/indo';

export default {
  formaFromImagen(imagen, datos = '', token = null) {
    const payload = config.getFormaFromImagen.xml
      .replace('{imagen}', imagen)
      .replace('{datos}', new XMLSerializer().serializeToString(document.createTextNode(datos)));

    return axios.post(
      axiosConfig.indoscanURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          // Authorization: `Bearer ${token}`,
        },
      },
    );
  },

};
